import React from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import Title from "../components/common/Title.jsx"
import Animation from "../components/common/Animation.jsx"

const Style = styled.div`
  p {
    text-align: center;
  }
`

export default () => (
  <Layout>
    <Style>
      <Title title="Der Reise-Blog von Julia und Toni" />
      <p>
        Herzlich Willkommen auf unserem Reise-Blog! Wir freuen uns, dass Du uns
        auf unseren kleinen Weltreise begleiten möchtest! Wir - Julia & Toni -
        wollten nicht länger auf den nächsten großen Urlaub warten, um neue
        Länder zu entdecken. Wir wollten ein Abenteuer erleben, raus aus dem
        Alltag...Deswegen haben wir es gewagt: haben ein Sabbatical genommen,
        die Rucksäcke gepackt und unsere Zelte daheim abgebrochen. Die nächsten
        Monate werden wir (fast) jeden Tag einen neuen Ort unser Zuhause nennen
        dürfen!
      </p>
      <Animation animation={require("../assets/coming-soon.json")} />
    </Style>
  </Layout>
)
